<!-- anchor-cooperation -->
<template>
  <div class="anchor-cooperation">
    <!-- 查询表单 -->
    <div class="anchor-cooperation-form">
      <el-form
        :model="model"
        inline
        size="small"
        label-width="70px"
        @submit.native.prevent
      >
        <el-form-item label="完成日期:">
          <el-date-picker
            v-model="model.cooperationTimeRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            start-placeholder="请选择"
            end-placeholder="请选择"
          />
        </el-form-item>

        <el-form-item label="搜索:">
          <el-input
            v-model.trim="model.keyword"
            placeholder="主播名称、直播间号、商务"
            clearable
            @keyup.native.enter="handleFormSearchClick"
          />
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="searching"
            @click="handleFormSearchClick"
            >查询
          </el-button>

          <el-button icon="el-icon-refresh" @click="handleFormResetClick"
            >重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 数据表格 -->
    <div class="anchor-cooperation-table">
      <el-table
        v-loading="loading"
        ref="table"
        :data="formattedCooperations"
        size="small"
      >
        <el-table-column
          label="主播名称"
          prop="anchorName"
          show-overflow-tooltip
        />

        <el-table-column label="直播平台" prop="platformName" />

        <el-table-column label="签约时间" prop="signTime" width="160px" />

        <el-table-column
          label="游戏名称"
          prop="gameName"
          show-overflow-tooltip
        />

        <el-table-column label="排期时间" prop="scheduleTime" width="160px" />

        <el-table-column label="合作方式">
          <template v-slot="{ row: { cooperationType } }">
            <div>{{ cooperationType | cooperationTypeText }}</div>
          </template>
        </el-table-column>

        <el-table-column label="投放时间" prop="publishTime" width="160px" />

        <el-table-column label="开播天数">
          <template v-slot="{ row }">
            <div>{{ row | broadcastedCountAndPercentage }}</div>
          </template>
        </el-table-column>

        <el-table-column label="流水数" prop="cooperationIncome" />

        <el-table-column label="注册数" prop="cooperationRegistration" />

        <el-table-column label="支出数" prop="cooperationExpenditure" />

        <el-table-column label="毛利润" prop="profit" />

        <el-table-column label="ROI">
          <template v-slot="{ row: { roi } }">
            <roi :value="roi" />
          </template>
        </el-table-column>

        <el-table-column label="商务" prop="username" />

        <el-table-column label="完成时间" prop="finishTime" width="160px" />

        <el-table-column label="操作" width="50px">
          <template v-slot="{ row }">
            <div class="table-row-operate">
              <el-button
                plain
                type="primary"
                icon="el-icon-view"
                circle
                size="mini"
                @click="handleTableRowViewClick(row)"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页器 -->
    <div class="anchor-cooperation-pagination">
      <el-pagination
        :total="total"
        :current-page="pageNo"
        :page-sizes="pageSizes"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePageNoChange"
        @size-change="handlePageSizeChange"
      />
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex';
  import GameName from '../../../components/game-name';
  import Roi from '../../../components/roi';
  import cooperationStatus from '../../../enum/cooperation-status.js';
  import cooperationType from '../../../enum/cooperation-type.js';
  import { pageSize, pageSizes } from '../../../config/pagination';

  export default {
    name: 'anchor-cooperation',
    components: {
      GameName,
      Roi,
    },
    filters: {
      // 合作方式文案
      cooperationTypeText(val) {
        return cooperationType.map[val] || '';
      },
      // 开播天数及开播率文案
      broadcastedCountAndPercentage(row) {
        const { signDays, broadcastedCount } = row;
        const broadcastedPercentage = signDays
          ? Math.round((broadcastedCount / signDays) * 100)
          : 100;

        return `${broadcastedCount} (${broadcastedPercentage}%)`;
      },
    },
    data() {
      return {
        model: null,
        pageNo: 1,
        pageSize,
        pageSizes,
        operateId: '',
        loading: false,
        searching: false,
      };
    },
    computed: {
      ...mapState('anchor/cooperation', ['cooperations', 'total']),

      formattedCooperations() {
        return this.cooperations.map(item => {
          const {
            cooperationIncome = 0,
            cooperationRegistration = 0,
            cooperationExpenditure = 0,
          } = item;
          const profit = cooperationIncome - cooperationExpenditure;

          item.cooperationIncome = cooperationIncome;
          item.cooperationRegistration = cooperationRegistration;
          item.cooperationExpenditure = cooperationExpenditure;
          item.profit = profit;
          item.roi = cooperationExpenditure
            ? profit / cooperationExpenditure
            : cooperationIncome;

          return item;
        });
      },
    },
    created() {
      this.initModel();
    },
    mounted() {
      this.getTableData();
    },
    beforeDestroy() {
      this.mttCooperationId();
    },
    methods: {
      ...mapMutations('anchor/cooperation', ['mttCooperationId']),
      ...mapActions('anchor/cooperation', ['actCooperations']),

      // 初始化模型
      initModel() {
        this.model = {
          cooperationTimeRange: [],
          keyword: '',
        };
      },
      // 获取表格数据
      async getTableData() {
        const { cooperationTimeRange } = this.model;
        const [startTime = '', endTime = ''] = cooperationTimeRange || [];

        this.loading = true;

        const success = await this.actCooperations({
          ...this.model,
          startTime,
          endTime,
          status: cooperationStatus.enum.completed,
          cooperationTimeRange: undefined,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
        });

        this.loading = false;

        return success;
      },
      // 表单查询按钮单击
      async handleFormSearchClick() {
        this.pageNo = 1;
        this.searching = true;

        await this.getTableData();

        this.searching = false;
      },
      // 表单重置按钮单击
      handleFormResetClick() {
        this.pageNo = 1;

        this.initModel();
        this.getTableData();
      },
      // 表格行查看按钮单击
      handleTableRowViewClick({ _id }) {
        this.operateId = _id;

        this.$router.push({
          name: 'anchor-cooperation-view',
          params: { id: _id },
        });
      },
      // 分页器页码改变
      handlePageNoChange(pageNo) {
        this.pageNo = pageNo;

        this.getTableData();
      },
      // 分页器条目数改变
      handlePageSizeChange(pageSize) {
        this.pageSize = pageSize;

        this.getTableData();
      },
    },
    watch: {
      operateId(n, o) {
        this.mttCooperationId(n);
      },
    },
  };
</script>

<style scoped lang="scss">
  .anchor-cooperation {
    // 查询表单
    .anchor-cooperation-form {
      .el-form-item {
        margin-right: 70px;
      }

      .el-input,
      .el-select,
      .el-date-editor {
        width: 220px;
      }
    }

    // 数据表格
    .anchor-cooperation-table {
      margin-top: 15px;

      // 操作按钮
      .table-row-operate {
        .el-button {
          min-width: auto;
        }
      }
    }

    // 分页器
    .anchor-cooperation-pagination {
      margin-top: 20px;
    }
  }
</style>
